import React from 'react';
import Img from "gatsby-image"

import SEO from '../components/seo';
import Layout from '../components/layout';
import SplitView from '../components/splitview';
import ContactForm from '../components/contact-form';

import { FaPhoneAlt } from "react-icons/fa"
import { MdMail } from "react-icons/md"

const ContactUs = ({ location }) => {
  return(
    <Layout location={location}>
      <SEO title="Contact Us" />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SplitView>
          <div className="contact-us">
            <h1>Contact Renno Specialists</h1>
            <p style={{ color: '#777', lineHeight: '1.5rem'}}>Questions about a construction project? Contact us directly at any of the phone numbers or email addresses below.</p>
            <div className="contact-info">
              <h3>Company email: <a href="mailto:sales@rennospecialists.com">sales@rennospecialists.com</a></h3>
              <h3 style={{marginTop: '2rem'}}>Company phone: <a href="tel:4806307579">1 (480) 630-7579</a></h3>
              
            </div>
            {/*<ContactForm />*/}
          </div>
        </SplitView>
      </div>
    </Layout>
  )
}

export default ContactUs